import React, { useContext } from "react"
import { TitleIcon, CardElevation, MaterialInput } from '@findep/mf-landings-core'

import Grid from '@material-ui/core/Grid';
import { css } from '@emotion/react'
import { Typography, Backdrop, CircularProgress, Box, Button, Select, FormHelperText, FormControl, InputLabel, MenuItem, Snackbar } from '@material-ui/core';

import TrendingFlatIcon from '@material-ui/icons/TrendingFlat'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { useState } from 'react';
import { useEffect } from 'react';
import CardFileUploadComponent from '../elements/fileupload/CardFileUploadComponent';
import { navigate } from '../../helpers/queryNavigate';
import { CataloguesService } from '../../services/LandingV4/catalogues';
import { Alert } from '@material-ui/lab';

import { IncomeIngresos } from "../../services/LandingV4";
import { GlobalStateFooterContext } from "../../context/GlobalStateFooterContext";
import getTenant from "../../helpers/getTenant";

const textSubtitle = css`        
        text-align: center;
        padding: 1rem;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: 0.15px;
        color: rgba(0, 0, 0, 0.87);
`

const rootBtn = css`
    border-radius: 20px;
`

const bDrop = css`  
    z-index:9999;
    color: '#fff';
  `

const styleTitle = css`
    margin-bottom: 1rem;
`
const root = css`
    padding: 1rem;
`

const cardMargin = css`
    margin-top: 1rem;
`;

const btnContainer = css`
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;        
`

const catalogues = new CataloguesService();

const ComprobanteIngresos = (props) => {
    const { setIsDynamic, setTenantFooter } = useContext(GlobalStateFooterContext);    

    const id = !((typeof window != 'undefined') && sessionStorage.getItem('flujo') === null) ? (typeof window != 'undefined') ? JSON.parse(sessionStorage.getItem('flujo')) : '' : ''
    const [subtitle, setSubtitle] = useState("A continuación ingresa el nombre de la empresa donde trabajas, y elige la frecuencia con la que recibes tus pagos.");
    const [data, setData] = useState([]);
    const [comprobante, setComprobante] = useState([]);
    const [select, setSelect] = useState('');
    const [activate, setActivate] = useState(true);
    const [success, setSuccess] = useState([]);
    const [successfileupload, setSuccessfileupload] = useState(false);
    const [employer, setEmployer] = useState('');
    const [errorservice, setErrorService] = useState(false);
    const [filename, setFilename] = useState(['Data']);
    const [bdrop, setBdrop] = useState(false)
    const [bajoCosto, setBajoCosto] = useState(false)
    const [titleUploads, setTitleUploads] = useState("")

    const { creditApplicationId } = id;

    useEffect(() => {
        const getTenantHelper = async () => {
            //GET TENANT        
            const tenantValue = await getTenant(creditApplicationId)
            setIsDynamic(true)
            setTenantFooter(tenantValue)
        }
        getTenantHelper()
    }, [])

    useEffect(() => {
        const getIncomeIngresos = async () => {
            await catalogues.getIncomeIngresos(creditApplicationId)
                .then((response) => {
                    setComprobante(response.data.paymentFrequencies);
                    setBajoCosto(response.data.isLowCost)
                })
                .catch(error => {
                    setErrorService(true);
                    console.log(error);
                })
        }
        getIncomeIngresos();
    }, [creditApplicationId]);

    useEffect(() => {
        const getIncomeSpec = async () => {
            const Frequency = comprobante.find(item => item.displayName === select);
            Frequency && (
                catalogues.getIncomeSpec(Frequency.id, creditApplicationId)
                    .then((response) => {
                        setData(response.data.documents);
                        setSubtitle(response.data.customerMessage);
                    })
                    .catch(error => {
                        setErrorService(true);
                        console.log(error);
                    })
            )
        }
        getIncomeSpec();
    }, [comprobante, select, creditApplicationId]);

    useEffect(() => {
        data.length > 0 &&
            data.length === (success).length
            ? setActivate(false) :
            setActivate(true)
    }, [data, success]);

    const handleNextStep = async () => {
        const navigateUrl = props.pageContext.next.path;
        const Income = new IncomeIngresos(creditApplicationId);
        Income.setEmployer(employer);
        await Income.buttonSend();
        navigate(navigateUrl);
    }

    const handleFile = async (file, flag, idx) => {

        const fileImg = file.value;
        const checkSuccess = success.find(item => item === idx);
        const income = new IncomeIngresos(creditApplicationId);
        const name = [...filename];
        name[idx] = file.value.name;
        income.setFile(fileImg)
        setBdrop(true);
        try {
            const { status } = await income.fileSend();
            (status === 200 || status === 204) && setSuccessfileupload(true)
            setBdrop(false);

            if (checkSuccess !== idx) {
                setSuccess([...success, idx])
                setFilename(name)
            }
        } catch (error) {
            //El envio al server fallo            
            setErrorService(true);
            setBdrop(false);
            console.error(error)
        }

    }
    const handleChange = (e) => {
        setEmployer(e.target.value);
    }

    const titles = (valor) => {
        valor === 'Semanal' && setTitleUploads('Si tus pagos los recibes de manera semanal, sube los últimos 4 recibos')
        valor === 'Quincenal' && setTitleUploads('Si tus pagos los recibes de manera quincenal, sube los últimos 2 recibos')
        valor === 'Mensual' && setTitleUploads('Si tus pagos los recibes de manera mensual, sube el del último mes')
    }

    const handleChangeSelect = (e) => {
        titles(e.target.value)
        setSelect(e.target.value);
        setSuccess([]);
        setData([]);
        const updateFileName = filename.map(item => '');
        setFilename(updateFileName);
    };

    // Select
    const renderComprobante = comprobante.map(item =>
        <MenuItem value={item.displayName} key={item.id}>{item.displayName}</MenuItem>
    )

    // CardFileUpload
    const uploadCard = data.length !== 0 && data.map((item, idx) =>
        <CardFileUploadComponent
            onChange={handleFile}
            type={item}
            id={idx}
            message={!bajoCosto ? item : ''}
            key={idx}
            fileName={filename[idx]}
            active={successfileupload}
        />
    );

    return (
        <>
            <Grid container justify="center">
                <Grid item justify="center">
                    <Grid container css={styleTitle} justify="center">
                        <TitleIcon title="Comprobante de Ingreso" icon={AttachMoneyIcon} textColor={true} />
                    </Grid>
                    <Typography css={textSubtitle}>
                        {!bajoCosto ? subtitle : 'En esta sección te pedimos que cargues los recibos de nómina del último mes:'}
                    </Typography>
                    <Grid item xs={12} md={12} justify="center" css={root}>
                        <CardElevation>
                            <Grid item xs={12}>
                                <MaterialInput
                                    type="text"
                                    id="comprobante-ingreso-nombre"
                                    label="Empresa"
                                    value={employer}
                                    pattern="[A-ZÑÁÉÍÓÚÜ]{1,}( [A-ZÑÁÉÍÓÚÜ]{1})?"
                                    accept=" A-ZÑÁÉÍÓÚÜ"
                                    transform={(value) => value.toUpperCase().slice(0, 60)}
                                    onChange={handleChange}
                                    required
                                    helperText="Nombre del lugar donde trabajas"
                                />
                                <br />
                                <FormControl variant="outlined" style={{ minWidth: 300 }}>
                                    <InputLabel id="carga-archivos-select-label" >Frecuencia</InputLabel>
                                    <Select
                                        onChange={handleChangeSelect}
                                        labelId="carga-archivos-select-label"
                                        id="carga-archivos-select"
                                        value={select}
                                        style={{ minWidth: 300 }}
                                        required={true}
                                        label="Frecuencia"
                                        disabled={!employer}
                                    >
                                        {
                                            renderComprobante
                                        }
                                    </Select>
                                    <FormHelperText>Elige la frecuencia en la que te pagan</FormHelperText>
                                </FormControl>

                            </Grid>
                        </CardElevation>
                        {select &&
                            <Grid item css={cardMargin}>
                                <CardElevation justify="center">
                                    {bajoCosto && <p>{titleUploads}</p>}
                                    {uploadCard}
                                </CardElevation>
                            </Grid>
                        }
                        <Box css={btnContainer}>
                            <Button
                                id="comprobante-ingresos-nextStep-button"
                                variant="contained"
                                color="primary"
                                startIcon={<TrendingFlatIcon />}
                                css={rootBtn}
                                disabled={activate}
                                onClick={handleNextStep}
                            >
                                Continuar
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
                <Backdrop css={bDrop} open={bdrop} >
                    <CircularProgress color="primary" />
                </Backdrop>
                <Snackbar open={errorservice} autoHideDuration={6000} onClose={() => setErrorService(false)}>
                    <Alert variant="filled" severity="warning" onClose={() => setErrorService(false)}>
                        Lo sentimos, por el momento el servicio no se encuentra disponible
                    </Alert>
                </Snackbar>
                <Snackbar open={successfileupload} autoHideDuration={1500} onClose={() => setSuccessfileupload(false)}>
                    <Alert variant="filled" severity="success" onClose={() => setSuccessfileupload(false)}>
                        Archivo subido exitosamente
                    </Alert>
                </Snackbar>
            </Grid>
            <br />
        </>
    );
}

export default ComprobanteIngresos;
